.contenttab {
    width: 100%;
    height: 80px;
    border-radius: 8px 8px 8px 8px;
    background-color: #f5f5f5;
    line-height: 80px;

    li {
        cursor: pointer;
    }

    :global {
        .contenttab_box{
            width: 1200px;
            line-height: 80px;
            margin: 0 auto;
        }
        .active {
            color: #42CD8C;
        }
    }

}