.box{
    height: 400px;
    background-color: #092743;
    color: #fff;
    width: 100%;
    position: relative;
    z-index: 999;
    :global {
        .page_bottom {
            margin: 0 auto;
            width: 1400px;
            padding-top: 60px;
            display: flex;
            justify-content: space-between;
            dl {
                width: 190px;
            }
            dl:last-child {
                width: 240px;
            }
            dd {
                margin-bottom: 24px;
            }
        }
        .absolute_bottom{
            position: absolute;
            bottom: 17px;
            width: 100%;
        }
        .te_D{
            color: #DDDDDD;
        }
        .clerk{
            text-decoration: none;
            &:hover {
                color: #fff;
                cursor: pointer;
            }
        }
    }
}