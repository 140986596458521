.location {
    width: 100%;
    min-width: 1500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    img {
        width: 162px;
        height: 40px;
        margin-left: 20px;
    }

    :global {
        .tips {
            background-color: #092743;
            height: 4px;
        }

        .page_head {
            width: 100%;
            height: 76px;
            background-color: #ffffff;
            z-index: 999;
            box-shadow: 0px 12px 8px -12px #999;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;    

            .head_btn {
                width: 104px;
                line-height: 40px;
                cursor: pointer;
                border: 1px solid #42CD8C;
            }

            .ant-menu-title-content {
                font-size: 18px;
                margin: 0 16px;
            }

            .ant-menu-horizontal {
                border-bottom: 0;
            }

            .menu_box{
                position: relative;
                cursor: pointer;
                transition: 0.3s;

                .menu_title{
                    padding: 10px 16px;
                }
    
                .in{
                    height: 2px;
                    background-color: #42CD8C;
                    transition: 0.3s;
                }
    
                .on{
                    height: 2px;
                    background-color: #ffffff;
                    transition: 0.3s;
                }

                a{
                    text-decoration: none;
                    color:#05172D;
                }

                a:hover, a:visited, a:link, a:active {
                    color:#05172D;
                }
            }
            .menu_box:hover{
                
                .on{
                    height: 2px;
                    background-color: #42CD8C;
                    transition: 0.3s;
                }
            }
            .dropdown_box{
                overflow: hidden;
                height: auto;
                position: absolute;
                border-radius: 10px;
                width: 160px;
                box-shadow: 0 0 5px #ccc;
                left: 0;
                top: 50px;
                background-color: #ffffff;
                // opacity: 1;
                // transition: 0.3s;
                .dropdown_box_title{
                    margin: 2px;
                    font-size: 14px;
                    padding: 10px 10px;
                    border-radius: 10px;
                    margin: 5px;
                }
                .dropdown_box_title:hover{
                    margin: 2px;
                    background: rgba(105, 105, 105, 0.1);
                    border-radius: 10px;
                    font-size: 14px;
                    padding: 10px 10px;
                    margin: 5px;
                }
                .dropdown_box_title_select{
                    background: rgba(186, 224, 255, 0.5);;
                }
            }
            // 下拉框
            .dropdown_box_hi{
                display: none;
                // opacity: 0;
            }
        }
    }
}