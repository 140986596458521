.sol {
    :global {
        .iconfont {
            font-size: 28px;
        }
        .code {
            display: flex;
            width: 1200px;
            justify-content: center;
            margin: 140px auto 0 auto;
            .left {
                width: 564px;
            }
        }
    }
}