.page_content {
    :global {
        .modular_box{
            background-color: #f8f8f8;
            padding-bottom: 60px;
            .modular_box_flex{
                width: 1200px;
                margin: 0 auto;
                padding: 60px 0 0 0;
                .modular_box_flex_content{
                    width: 368px;
                    margin-right: 48px;
                    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.15);
                    transition: all 0.2s ease-out;
                    transform:translateY(0px);
                    &:hover{
                        transform:translateY(-20px);
                    }
                    .modular_box_flex_content_top{
                        border-radius: 8px 8px 0px 0px;
                        position: relative;
                        width: 368px;
                        height: 160px;
                        img{
                            width: 368px;
                            height: 160px;
                        }
                        .modular_box_flex_content_top_tips{
                            border-radius: 8px 8px 0px 0px;
                            width: 368px;
                            height: 160px;
                            background: rgba(0,0,0,0.25);
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            padding: 118px 0 0 16px;
                        }
                    }
                    .modular_box_flex_content_bottom{
                        height: 68px;
                        padding: 0 16px;
                    }
                }
            }
        }
    }
}