.page_content {
    :global {
        .banner {
            background-color: rgba(59, 174, 209, 0.5);
            background-size: cover;
            height: 500px;
            width: 100%;
            position: relative;

            .banner_bottom {
                height: 64px;
                background: rgba(0, 0, 0, 0.5);
                width: 100%;
                position: absolute;
                bottom: 0;

                .banner_bottom_box {
                    opacity: 0.9;
                    height: 64px;
                    width: 1200px;
                    margin: 0 auto;
                }
            }
        }

        .modular_one {
            background-color: #f8f8f8;

            .modular_one_flex {
                width: 1200px;
                margin: 0 auto;

                .modular_one_flex_box {
                    width: 282px;
                    height: 282px;
                    box-shadow: 0 0 10px #f8f8f8;
                    border: 1px solid #FFFFFF;
                    transition: all 0.2s ease-out;
                    transform: translateY(0px);

                    &:hover {
                        border: 1px solid #42CD8C;
                        color: #42CD8C;
                        transform: translateY(-20px);
                        box-shadow: 0 0 16px 0 #BCDCCD;
                    }
                }
            }
        }

        .modular_two {
            .modular_two_flex {
                width: 1200px;
                margin: 0 auto;

                .modular_two_flex_img {
                    filter: blur(1px);
                    width: 588px;
                    height: 234px;
                    display: block;
                }

                .modular_two_flex_box {
                    position: relative;
                    width: 588px;
                    height: 234px;
                    background-size: 100%;
                    transition: all 0.2s ease-in-out 0s;

                    &:hover {
                        transform: scale(1.082, 1.182);
                        z-index: 99;
                        background-size: 110%;
                    }

                    .modular_two_flex_box_text {
                        width: 588px;
                        height: 234px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all 0.2s ease-in-out 0s;
                        background: rgba(0, 0, 0, 0.3);

                        &:hover {
                            // backdrop-filter: saturate(300%) blur(1px);
                            background: rgba(66, 205, 140, 0.9);
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }

        .modular_three {

            .ant-tabs-top,
            .ant-tabs-nav,
            .ant-tabs-nav::before {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: none;
            }

            .ant-tabs-tab {
                font-size: 22px;
                width: 88px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .modular_three_flex {
                width: 1200px;
                margin: 0 auto;

                .modular_three_flex_img1 {
                    background: url('https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/6.png') no-repeat center center;
                }

                .modular_three_flex_img2 {
                    background: url('https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/7.png') no-repeat center center;
                }

                .modular_three_flex_img3 {
                    background: url('https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/8.png') no-repeat center center;
                }

                .modular_three_flex_box {
                    position: relative;
                    width: 384px;
                    height: 200px;
                    background-size: 100%;
                    transition: all 0.2s ease-out;
                    transform: translateY(0);

                    &:hover {
                        transform: translateY(-20px);
                    }

                    .modular_three_flex_box_text {
                        width: 240px;
                        height: 200px;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }

            .modular_three_btn {
                width: 320px;
                line-height: 60px;
                margin: 48px auto;
                cursor: pointer;
            }
        }
    }
}