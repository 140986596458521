* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

#root {
  font-size: 14px;
  min-width: 1500px;
  font-family: Source Han Sans CN;
}

li {
  list-style: none;
}

/* 以下是chrome浏览器自定义滚动条样式方式 */
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track{
  background: rgb(255, 255, 255);
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb{
  background: rgba(182, 188, 197, 1);
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: rgb(162, 162, 163);
}