/* ==================
		内外边距
 ==================== */
.ma0 {
	margin: 0;
}
.ma10 {
	margin: 10px;
}
.ma20 {
	margin: 20px;
}
.ma30 {
	margin: 30px;
}
.ma40 {
	margin: 40px;
}
.ma50 {
	margin: 50px;
}
.ma_top10 {
	margin-top: 10px;
}
.ma_top20 {
	margin-top: 20px;
}
.ma_top30 {
	margin-top: 30px;
}
.ma_top40 {
	margin-top: 40px;
}
.ma_top50 {
	margin-top: 50px;
}
.ma_right10 {
	margin-right: 10px;
}
.ma_right20 {
	margin-right: 20px;
}
.ma_right30 {
	margin-right: 30px;
}
.ma_right40 {
	margin-right: 40px;
}
.ma_right50 {
	margin-right: 50px;
}
.ma_bottom10 {
	margin-bottom: 10px;
}
.ma_bottom20 {
	margin-bottom: 20px;
}
.ma_bottom30 {
	margin-bottom: 30px;
}
.ma_bottom40 {
	margin-bottom: 40px;
}
.ma_bottom50 {
	margin-bottom: 50px;
}
.ma_left10 {
	margin-left: 10px;
}
.ma_left20 {
	margin-left: 20px;
}
.ma_left30 {
	margin-left: 30px;
}
.ma_left40 {
	margin-left: 40px;
}
.ma_left50 {
	margin-left: 50px;
}
.ma_lr10 {
	margin-left: 10px;
	margin-right: 10px;
}
.ma_lr20 {
	margin-left: 20px;
	margin-right: 20px;
}
.ma_lr30 {
	margin-left: 30px;
	margin-right: 30px;
}
.ma_lr40 {
	margin-left: 40px;
	margin-right: 40px;
}
.ma_lr50 {
	margin-left: 50px;
	margin-right: 50px;
}
.ma_tb10 {
	margin-top: 10px;
	margin-bottom: 10px;
}
.ma_tb20 {
	margin-top: 20px;
	margin-bottom: 20px;
}
.ma_tb30 {
	margin-top: 30px;
	margin-bottom: 30px;
}
.ma_tb40 {
	margin-top: 40px;
	margin-bottom: 40px;
}
.ma_tb50 {
	margin-top: 50px;
	margin-bottom: 50px;
}
.pa0 {
	padding: 0;
}
.pa10 {
	padding: 10px;
}
.pa20 {
	padding: 20px;
}
.pa30 {
	padding: 30px;
}
.pa40 {
	padding: 40px;
}
.pa50 {
	padding: 50px;
}
.pa_top10 {
	padding-top: 10px;
}
.pa_top20 {
	padding-top: 20px;
}
.pa_top30 {
	padding-top: 30px;
}
.pa_top40 {
	padding-top: 40px;
}
.pa_top50 {
	padding-top: 50px;
}
.pa_right10 {
	padding-right: 10px;
}
.pa_right20 {
	padding-right: 20px;
}
.pa_right30 {
	padding-right: 30px;
}
.pa_right40 {
	padding-right: 40px;
}
.pa_right50 {
	padding-right: 50px;
}
.pa_bottom10 {
	padding-bottom: 10px;
}
.pa_bottom20 {
	padding-bottom: 20px;
}
.pa_bottom30 {
	padding-bottom: 30px;
}
.pa_bottom40 {
	padding-bottom: 40px;
}
.pa_bottom50 {
	padding-bottom: 50px;
}
.pa_left10 {
	padding-left: 10px;
}
.pa_left20 {
	padding-left: 20px;
}
.pa_left30 {
	padding-left: 30px;
}
.pa_left40 {
	padding-left: 40px;
}
.pa_left50 {
	padding-left: 50px;
}
.pa_lr10 {
	padding-left: 10px;
	padding-right: 10px;
}
.pa_lr20 {
	padding-left: 20px;
	padding-right: 20px;
}
.pa_lr30 {
	padding-left: 30px;
	padding-right: 30px;
}
.pa_lr40 {
	padding-left: 40px;
	padding-right: 40px;
}
.pa_lr50 {
	padding-left: 50px;
	padding-right: 50px;
}
.pa_tb10 {
	padding-top: 10px;
	padding-bottom: 10px;
}
.pa_tb20 {
	padding-top: 20px;
	padding-bottom: 20px;
}
.pa_tb30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
.pa_tb40 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.pa_tb50 {
	padding-top: 50px;
	padding-bottom: 50px;
}
/* ==================
		  字体样式
 ==================== */
.f_s12{
	font-size: 12px;
}
.f_s14{
	font-size: 14px;
}
.f_s16{
	font-size: 16px;
}
.f_s18{
	font-size: 18px;
}
.f_s20{
	font-size: 20px;
}
.f_s22{
	font-size: 22px;
}
.f_s24{
	font-size: 24px;
}
.f_s26{
	font-size: 26px;
}
.f_s28{
	font-size: 28px;
}
.f_s30{
	font-size: 30px;
}
.f_s32{
	font-size: 32px;
}
.f_s34{
	font-size: 34px;
}
.f_s36{
	font-size: 36px;
}
.f_s38{
	font-size: 38px;
}
.f_s40{
	font-size: 40px;
}
.f_s44{
	font-size: 44px;
}
.f_s48{
	font-size: 48px;
}
.f_s52{
	font-size: 52px;
}
.f_s54{
	font-size: 54px;
}
.f_s56{
	font-size: 56px;
}
.f_s58{
	font-size: 58px;
}
.f_s60{
	font-size: 60px;
}
.fw_bold {
	font-weight: bold;
}
/* ==================
		  图形样式
 ==================== */
.bo_radius4 {
	border-radius: 4px;
}
.bo_radius6 {
	border-radius: 6px;
}
.bo_radius8 {
	border-radius: 8px;
}
.bo_radius10 {
	border-radius: 10px;
}
.bo_radius14 {
	border-radius: 14px;
}
.bo_radius18 {
	border-radius: 18px;
}
.bo_radius22 {
	border-radius: 22px;
}
.bo_radius30 {
	border-radius: 30px;
}
.bo_radius38 {
	border-radius: 38px;
}
.bo_radius50 {
	border-radius: 50%;
}