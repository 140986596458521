.page_content {
    background-color: #F8F8F8;
    padding: 50px 0;

    :global {
        .modular_box {
            width: 1200px;
            height: 764px;
            margin: 0 auto;
            border: 1px solid #E9E9E9;
            position: relative;

            &_title {
                width: 1200px;
                height: 66px;
                border-bottom: 1px solid #E9E9E9;
                padding: 18px 40px 24px 40px;
            }

            &_table {
                height: 622px;

                [alt] {
                    width: 100%;
                }

                &_html {
                    height: 622px;
                    overflow: auto;
                }

                tr {
                    cursor: pointer;
                }
            }

            &_btn {
                width: 104px;
                line-height: 40px;
                border: 1px solid #CCCCCC;
                margin: 0px auto;
                cursor: pointer;
            }

            table {
                padding-right: 10px;
            }
        }

        .tips {
            padding: 2px 5px;
            line-height: 12px;
            background-color: #EE502F;
        }

        :where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper .ant-table-expanded-row-fixed {
            height: 611px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        :where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper .ant-table-tbody>tr>td,
        :where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper tfoot>tr>th,
        :where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper tfoot>tr>td {
            padding: 19px 0;
        }

        .fade-enter {
            opacity: 0;
        }

        .fade-enter.fade-enter-active {
            opacity: 1;
            transition: opacity 200ms ease-in;
        }

        .fade-exit {
            opacity: 1;
        }

        .fade-exit.fade-exit-active {
            opacity: 0;
            transition: opacity 200ms ease-in;
        }
    }
}