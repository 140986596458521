.banner {
    height: 500px;
    width: 100%;
    position: relative;

    :global {


        .banner_img {
            height: 500px;
            width: 100%;
            object-fit: cover;
            display: block;
        }



        .banner_box {
            position: absolute;
            top: 0;
            left: 0;
            height: 500px;
            width: 100%;
            // background-color: rgba(#0B2560, 0.4);

            &_title {
                letter-spacing: 10px;
                font-size: 70px;
            }

            &_tips {
                letter-spacing: 10px;
                font-size: 36px;
            }
        }

        .banner_bottom {
            cursor: pointer;
            height: 64px;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            position: absolute;
            bottom: 0;


            .item {
                &:hover {
                    color: #42CD8C;
                }
            }

            &_box {
                opacity: 0.9;
                height: 64px;
                width: 1200px;
                margin: 0 auto;
            }
        }
    }
}