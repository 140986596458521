/* ==================
		  布局
 ==================== */
.flex1 {
    flex: 1;
}
.flex {
   display: flex;
}
.flex_column {
   flex-direction: column;
}
.flex_wrap {
   flex-wrap: wrap;
}
.justify_start {
   justify-content: flex-start;
}
.justify_end {
   justify-content: flex-end;
}
.justify_center {
   justify-content: center;
}
.justify_between {
   justify-content: space-between;
}
.justify_around {
   justify-content: space-around;
}
.align_start {
   align-items: flex-start;
}
.align_end {
   align-items: flex-end;
}
.align_center {
   align-items: center;
}
.break_word {
	word-wrap: break-word
}
.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.ellipsis2{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.te_center {
	text-align: center;
}
.te_right {
	text-align: right;
}
.te_left {
	text-align: left;
}
.te_justify {
	text-align: justify;
}
.te_through{
	text-decoration: line-through;
}
/* ==================
		  透明
 ==================== */
.c_opa5{
	opacity:0.5;
}
/* ==================
		  颜色
 ==================== */
$red: #F7271D;
$orange: #FF7F00;
$yellow: #FFB22E;
$golden: #F7D5B0;
$olive: #8dc63f;
$green: #42CD8C;
$cyan: #1cbbb4;
$blue: #0091ED;
$purple: #6739b6;
$mauve: #9c26b0;
$pink: #fff5f4;
$brown: #4B2D12;
$white: #ffffff;
$black3: #05172D;
$black6: #666666;
$black9: #999999;
.te_red{
	color: $red;
}
.te_orange{
	color: $orange;
}
.te_yellow{
	color: $yellow;
}
.te_golden{
	color: $golden;
}
.te_olive{
	color: $olive;
}
.te_green{
	color: $green;
}
.te_cyan{
	color: $cyan;
}
.te_blue{
	color: $blue;
}
.te_purple{
	color: $purple;
}
.te_purple{
	color: $purple;
}
.te_mauve{
	color: $mauve;
}
.te_pink{
	color: $pink;
}
.te_brown{
	color: $brown;
}
.te_white{
	color: $white;
}
.te_black3{
	color: $black3;
}
.te_black6{
	color: $black6;
}
.te_black9{
	color: $black9;
}
.bg_red {
	background-color: $red;
}
.bg_orange {
    background-color: $orange;
}
.bg_yellow {
    background-color: $yellow;
}
.bg_golden {
    background-color: $golden;
}
.bg_olive {
    background-color: $olive;
}
.bg_green {
    background-color: $green;
}
.bg_cyan {
    background-color: $cyan;
}
.bg_blue {
    background-color: $blue;
}
.bg_purple {
    background-color: $purple;
}
.bg_mauve {
    background-color: $mauve;
}
.bg_pink {
    background-color: $pink;
}
.bg_brown {
    background-color: $brown;
}
.bg_white {
    background-color: $white;
}
.bg_black3 {
    background-color: $black3;
}
.bg_black6 {
    background-color: $black6;
}
.bg_black9 {
    background-color: $black9;
}
/* ==================
		  组件样式
 ==================== */
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab:hover,
.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a{
	color: #42CD8C !important;
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after,
.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after{
	border-bottom-color: #42CD8C !important;
}
.ant-tabs .ant-tabs-ink-bar{
	background: #42CD8C !important;
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected{
	background-color: #f5f7fa !important;
}
.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover,
.ant-pagination .ant-pagination-options-quick-jumper input:focus, 
.ant-pagination .ant-pagination-options-quick-jumper input-focused,
.ant-pagination .ant-pagination-options-quick-jumper input:hover,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-focused .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
	border-color: #42CD8C !important;
}
:where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper .ant-table-thead>tr>th, 
:where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper .ant-table-tbody>tr>td, 
:where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper tfoot>tr>th, 
:where(.css-dev-only-do-not-override-1s3dcof).ant-table-wrapper tfoot>tr>td{
	padding: 16px 0;
	color: #5D5D5D;
	font-size: 14px;
}
/* ==================
		  路由过渡动画
 ==================== */
.fade-enter {
	opacity: 0;
}
.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}
.fade-exit {
	opacity: 1;
}
.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 500ms ease-in;
}